@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');

.base {
//   background-color: #f5f5f5;
  font-family: sans-serif;
  padding: 200px 0;
  //   width: 100%;
  font-weight: 300;
  box-sizing: border-box;
  color: #666666;

  > * {
    box-sizing: border-box;
  }

  > div {
    button {
        margin: 0 100px;
    }
  }
}

.home_button {
  width: 200px;
  align-items: center;
  background-color: #78bcc0;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%);
  text-align: center;

  &:hover {
    background-color: #6ba5a9;
    border: none;
  }
}

.back_button {
  width: 200px;
  align-items: center;
  background-color: #6c757d;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%);
  text-align: center;

  &:hover {
    background-color: #575e64;
    color: white;
    border: none;
  }
}
