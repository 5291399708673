.login_footer {
  background: #4e4d4e;
  color: #fff;
  text-align: center;
  font-size: 12px!important;
  padding: 10px 0;
}

.copyrights {
  margin: 0;
}
