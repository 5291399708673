.container {
  position: relative;

  .tool_tip {
    position: absolute;
    width: 200px;
    z-index: 2000;
    left: 50%;
    bottom: 80%;
    margin-bottom: 15px;
    padding: 5px;
    border-radius: 10px;
    background-color: #666;
    font-size: 0.7rem;
    color: #fff;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transform: translateX(-50%);
    transition: 0.5s all;
    line-height: 20px;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top: 12px solid #666;
      position: absolute;
      top: 99%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.container:hover .tool_tip {
  bottom: 100%;
  visibility: visible;
  opacity: 1;
}
