@import '../custom.scss';
@import '../pages/common.module.scss';

.wrapper_header {
  padding: 0;
  width: 100%;
  color: $body-color;
  @include pc {
    height: 56px;
  }
  @include tab {
    height: 50px;
  }
  @include sp {
    height: 50px;
  }

  div {
    margin-left: 0;
    margin-right: 0;
  }
}
.breadcrumb_layout{
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  border-bottom: 1px solid #dee2e6;
  margin: 0;
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  @include pc {
    height: 38px;
    font-size: 0.75rem;
    line-height: 18px;
    padding: 10px  ;
  }
  @include tab {
    height: 30px;
    font-size:  0.6875rem;
    padding: 5px 0;
  }
  @include sp {
    height: 30px;
    font-size:  0.6875rem;
    padding: 5px 0;
  }
}
.wrapper_header2 {
  padding: 0;
  border-radius: 0!important;
  div {
    margin-left: 0;
    margin-right: 0;
  }
}

.wrapper_row {
  position: relative;
}

.wrapper_col1 {
  position: absolute;
  padding-left: 0;
  padding-right: 0;

  left: 0;
  z-index: 10000; // spのときに必要
}

.wrapper_col1 {
  position: absolute;
  padding-left: 0;
  padding-right: 0;

  left: 0;
  z-index: 10000; // spのときに必要
}

.wrapper_col1_sidebar_open {
  transition: 0.5s;
  opacity: 1;
}

.wrapper_col1_sidebar_close {
  transition: 0.5s;
  opacity: 0;
}

.wrapper_col2 {
  position: absolute;
  padding-left: 0;
  padding-right: 0;

  left: 0;
  //width: 100vw;
  width: 100%; // vwではく%指定だとスクロールバーが干渉しない
}

.wrapper_col2_sidebar_open {
  @include pc {
    left: $sidebar_width;
    //width: calc(100vw - 370px); //（20px分はブラウザ縦スクロールバーの幅）
    width: calc(100% - #{$sidebar_width}); // vwではく%指定だとスクロールバーが干渉しない）
  }
}

.wrapper_col2_sidebar_close {
  // 何もしない
}
.flex_wrapper{
  display: flex;
  flex-wrap: wrap;
}
.select_item_container{
  @include pc {
    position: absolute;
    right: 1.25rem;
  }
  @include tab {

  }
  @include sp {

  }
}
.select_item_button_right_container{
  @include pc {
    right: 120px;
  }
  @include tab {

  }
  @include sp {

  }
}
.select_item_copy_right_container{
  @include pc {
    right: 185px;
  }
  @include tab {

  }
  @include sp {

  }
}

