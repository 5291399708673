.modal {
  font-size: 16px;
  font-weight: 300;
  min-width: 375px;

  .modal_header {
    background-color: #78bcc0;
    color: white;
    border: 1px solid #78bcc0;

    > div {
      font-weight: 700;
      margin: 0;
    }
  }

  .error {
    color: #eb5757;
  }
}

.modal_sub_belong {
  font-size: 16px;
  font-weight: 300;
  .modal-dialog {
    min-width: 620px;
  }
  .modal-content {
    min-width: 620px;
  }
  .modal_header {
    background-color: #78bcc0;
    color: white;
    border: 1px solid #78bcc0;

    > div {
      font-weight: 700;
      margin: 0;
    }
  }

  .error {
    color: #eb5757;
  }
}
