.full_overlay{
  position: absolute;
  left: 0; top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  z-index: 2147483647; // 最前面
}

.text{
  position: absolute;
  left: 50%; top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  color: rgb(0, 0, 0);
  font-size: 24px;
  text-align: center;
}

.center {
  display: flex;
  justify-content: center; // 縦
  align-items: center; // 横
  //border: 1px solid #B9B2A2;
  //border-radius: 10px;
  width: 100%;
  height: 50rem; // 高さの領域
  //margin-bottom: 16px;
}
