.full_overlay {
  position:fixed;
  left: 0; top: 0;
  width: 100%;
  height: 100%;
  background-color:rgba(0,0,0,0.5);

  /*　画面の中央に要素を表示させる設定　*/
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 100; // sidebarLayoutと同じ値
}