@import '../custom.scss';
@import '../pages/common.module.scss';

.wrapper {
  @include pc {
    height: calc(100vh - 56px);
  }
  @include tab {
    height: calc(100vh - 50px);
  }
  @include sp {
    height: calc(100vh - 50px);
  }
  overflow-y: scroll;
  position: fixed;
  background-color: #333C44!important;
  @include pc {
    height: calc(100vh - #{$header_height});
  }
}

/*スクロールバーの背景色・角丸指定*/
.wrapper::-webkit-scrollbar {
  width: 14px;
  border-radius: 5px;
  background: #7b7a87;
}

/*スクロールバーの色・角丸指定*/
.wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #4dc0cf;
}

.nav_sidebar {
  //position: fixed;
  font-size: 14px;
  z-index: 100; /* Behind the navbar */
  //padding: 48px 0 0; /* Height of navbar */
  padding: 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  font-family: sans-serif;
  width: 280px;
  background-color: transparent;
}

.sidebar {
  font-weight: 700;
  box-sizing: border-box;
  background-color: transparent;
  @include pc {
    height: calc(100% - 56px);
    padding-bottom: 20px;
  }
  @include tab {
    height: calc(100% - 50px);
    padding-bottom: 100px;
  }
  @include sp {
    height: calc(100% - 50px);
    padding-bottom: 100px;
  }

  .top {
    color: white;
    padding: 5px 0 5px 10px;
    border-radius: 8px;
    width: auto;
    margin: 0 20px 10px 15px;
    display: flex;
    align-items: center;

    > span {
      line-height: 1.5rem;
    }
  }
  .top:focus {
    background-color: transparent!important;
  }
  button.disabled:hover {
    color: #848788;
  }

  .subtitle {
    //background-color: #e3f7fa;
    color: #9fa1a2;
    padding: 8px 0 8px 10px;
    border-radius:0 8px 8px 0;
    border: 0!important;
    width: 95%;
    margin: 10px 0 20px 0;
    background-color: #14181b;
  }

  button {
    color: #848788;
    font-weight: 700;
    padding: 5px 0 5px 10px;
    width: auto;
    margin: 0 20px 10px 15px;
    text-align: start;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;

    &:hover {
      color: #F5F6F6;
      background-color: transparent;
    }

    > span {
      margin-left: 0.5rem;
    }
  }

  .event_button {
    width: 170px;
    padding: 5px 10px;
    margin: 10px 0 10px 20px;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
    border: 1px solid #23C4BA;
    border-radius: 7px;
    text-align: center;
    width: 90%;

    &:hover {
      background-color: rgba(238, 238, 238, 0.8) !important;
    }
  }
}
.sidebar_button_wrapper {
  @include sp {
    height: 76px;
  }
}