@import '../pages/common.module.scss';

.action_slow{
  @include action_slow;
}
.text_middle_size{
  @include text_middle_size;
}
.shadow{
  @include shadow;
}
.block_relative{
  @include block-relative;
}
.top_lg_space{
  @include top_lg_space;
}
.text_bt_mg{
  @include text_bt_mg;
}
.text_p{
  @include text_p;
}
.button {
  @include button(#78bcc0, white);
  display: block;
  @include pc {
    width: 330px;
    margin-left: auto;
    margin-right: auto;
  }
  @include tab {
    width: 100%;
  }
  @include sp {
    width: 100%;
  }
  font-size: 1rem;

  &:hover,
  &:active {
    color: #FFF;
    background-color: #32a1a9;
  }
}

.password_title {
  width: 100%;
  margin-bottom: 20px;
  color: #23C4BA;
  font-size: 21px;
  font-weight: 900;
  text-align: center;
}

.password_desc {
  margin-bottom: 10px;
  color: #4d4d4d;
}
.password_reveal {
  left: auto;
  position: absolute;
  right: 40px;
  top: 7px;
}
.simple_h2 {
  @include simple_h2;
}
.label {
  font-weight: 700;
  margin-bottom: 5px;
}
.value_text {
  margin-bottom: 0;
}
.value_wrapper {
  margin-bottom: 0.9375rem;
}
.alert_mini_padding {
  @include alert_mini_padding;
}
.m_b_15 {
  @include m_b_15;
}
.m_t_15 {
  @include m_t_15;
}