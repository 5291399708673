@import '../pages/common.module.scss';
.modal {
  font-size: 16px;
  font-weight: 300;
  min-width: 375px;

  .modal_header {
    background-color: #78bcc0;
    color: white;
    border: 1px solid #78bcc0;

    > div {
      font-weight: 700;
      margin: 0;
    }
  }

  .error {
    color: #eb5757;
  }
}

.modal_sub_belong {
  font-size: 16px;
  font-weight: 300;
  .modal-dialog {
    min-width: 620px;
  }
  .modal-content {
    min-width: 620px;
  }
  .modal_header {
    background-color: #78bcc0;
    color: white;
    border: 1px solid #78bcc0;

    > div {
      font-weight: 700;
      margin: 0;
    }
  }
}
.error {
  color: #eb5757;
}
.alert_mini_padding {
  @include alert_mini_padding;
}
.m_b_15 {
  @include m_b_15;
}
.m_t_15 {
  @include m_t_15;
}
.sub_belong_msg{
  @include pc {
    font-size: 1rem;
  }
  @include tab {
    font-size: 0.8125rem;
  }
  @include sp {
    font-size: 0.750rem;
  }
}