@import './common.module.scss';
@import '../custom.scss';

.header_title {
  @include header_title;
}

.form_group {
  @include form_group;
}

.shop_index {
  z-index: 100;
}

.result_group {
  @include result_group;
}

.card_layout {
  @include card_width;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  .card_header {
    background-color: $main-color;
    font-weight: 700;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .white_card_header {
    background-color: $main-color;
    font-weight: 700;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include pc {
      padding: 0.75rem 1.25rem;
      line-height: 1.625rem;
    }
    @include tab {

    }
    @include sp {
      padding: 0.625rem 0.46875rem;
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  }
  .excel_button {
    @include downloadButton(#6c757d);
    position: absolute;
    @include pc {
      top: 7px;
      right: 1.25rem;
    }
    @include tab {

    }
    @include sp {
      top: 5px;
      right: 0.46875rem;
    }
    svg {
      font-size: 20px;
      color: #1ea362;
      margin-top: -4px;
    }
    span {
      margin-left: 5px;
      color: #6c757d;
      font-weight: 400;
    }
  }

  .excel_download_tooltip_left {
    left: 15%;
  }

  .pdf_download {
    @include downloadButton(#6c757d);
    position: absolute;
    @include pc {
      top: 7px;
      right: 7.5rem;
    }
    @include tab {
      top: 5px;
      right: 0.46875rem;
    }
    @include sp {
      top: 5px;
      right: 85px;
    }
    > svg {
      font-size: 20px;
      color: #c81822;
    }
    > span {
      margin-left: 5px;
    }
  }
  .scroll_table {
    @include table_layout;
    margin: 0;

    @include pc {
      max-height: calc(100vh - 56px - 38px - 55px - 20px - 28px - 51px - 10px);
    }
    @include tab {
      max-height: calc(100vh - 50px - 30px - 107px - 10px - 39px - 26px - 10px);
    }
    @include sp {
      max-height: calc(100vh - 50px - 30px - 107px - 10px - 39px - 26px - 10px);
    }


    table {
      border-collapse: collapse;
      min-width: 100%;

      //th,
      //td {
      //  padding: 1%;
      //  width: 350px;
      //  min-width: 350px;
      //}

      td {
        > button {
          background-color: white;
          border: none;
          > img {
            width: 50%;
            height: auto;
          }
        }
      }

      @include tab {
        th,
        //td:nth-child(2) {
        //  height: auto;
        //  width: 30px;
        //  min-width: 30px;
        //}
        td {
          height: auto;
          width: 300px;
          min-width: 300px;
        }
      }

      thead {
        tr {
          &:first-child {
            th {
              background-color: $sub-color;
              &:first-child {
                z-index: 10;
              }
            }
          }
        }
      }
    }
  }
  .scroll_table tr:first-child th,
  .scroll_table tr th:first-child {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #E9E9E9;
  }
  .scroll_table tr:nth-child(odd) th {
    background-color: #F3F3F3;
  }

  .scroll_table tr:first-child th:before,
  .scroll_table tr th:first-child:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 1px;
    width: 100%;
    height: 100%;
    border-top: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
  }
}
.enquete_table {
  tr th:first-child{
    @include pc {
      padding: 0.625rem 0.9375rem;
    }
    @include tab {
    }
    @include sp {
    }
  }
  th:nth-child(2) {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 2!important;
    @include pc {
      min-width: 75px;
      max-width: 75px;
      width: 75px;
      left: 300px!important;
    }
    @include tab {
      min-width: 34px;
      max-width: 34px;
      width: 34px;
      left: 170px!important;;
    }
    @include sp {
      min-width: 34px;
      max-width: 34px;
      width: 34px;
      left: 170px!important;;
    }
  }
  td:nth-child(2) {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1;
    @include pc {
      min-width: 75px;
      max-width: 75px;
      width: 75px;
      left: 300px
    }
    @include tab {
      max-height: 130px;
      left: 650px;
    }
    @include sp {
      min-width: 34px;
      max-width: 34px;
      width: 34px;
      left: 170px;
    }
  }
  th:not(:nth-child(2)) {
    @include pc {
      min-width: 300px;
      max-width: 300px;
      width: 300px;
    }
    @include tab {
      max-height: 650px;
    }
    @include sp {
      min-width: 170px;
      max-width: 170px;
      width: 170px;
    }
  }
  td {
    @include pc {
      min-width: 300px;
      max-width: 300px;
      width: 300px;
    }
    @include tab {
      max-height: 650px;
    }
    @include sp {
      min-width: 220px;
      max-width: 220px;
      width: 220px;
    }
  }
  tr {
    background-color: #FAFAFA;
  }
  tr:nth-child(odd) td{
    background-color: #FFF;
  }
  .point_odd {
    background-color: #F3F3F3!important;
  }
  .point_even {
    background-color: #E9E9E9!important;
  }
}

.alert_red {
  background: #f66565!important;
  color: #ffffff;
}
.score_right {
  text-align: right;
}

.pagination_center {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center; // 縦
  align-items: center; // 横
  width: 100%;
}
.spiner_wrapper {
  @include pc {
    height: 400px;
  }
  @include tab {
    height: 200px;
  }
  @include sp {
    height: 100px;
  }
}
