@import '../pages/common.module.scss';

.main_title {
  font-weight: 700;
  @include pc {
    font-size: 24px!important;
    height: 38px;
    margin: 0;
    line-height: 38px;
  }
  @include tab {
    font-size: 22px!important;
    height: 28px;
    margin: 0;
    line-height: 28px;
  }
  @include sp {
    font-size: 1.125rem!important;
    height: 1.75rem;
    margin: 0 0 5px 0;
    line-height: 1.75rem;
  }
}