$main-color: #23C4BA;
$sub-color: #D5F1F3;
$font-color: #383838;
$danger-color: #dc3545;

@mixin sp {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin tab {
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin pc {
  @media only screen and (min-width: 992px) {
    @content;
  }
}
@mixin top_lg_space {
  @include pc {
    margin-top: 20px !important;
  }
}
@mixin bottom_lg_space {
  @include pc {
    margin-bottom: 1.3125rem !important;
  }
  @include tab {
    margin-bottom: 0.625rem !important;
  }
  @include sp {
    margin-bottom: 0.625rem !important;
  }
}
@mixin top_md_space {
  @include pc {
    margin-top: 15px !important;
  }
}
@mixin bottom_md_space {
  @include pc {
    margin-bottom: 15px !important;
  }
}
@mixin tab_sp_top_none {
  @include tab {
    border-top: none!important;
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
  }
  @include sp {
    border-bottom: none!important;
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
  }
}
@mixin tab_sp_bottom_none {
  @include tab {
    border-bottom: none!important;
    border-bottom-left-radius: 0!important;
    border-bottom-right-radius: 0!important;
  }
  @include sp {
    border-bottom: none!important;
    border-bottom-left-radius: 0!important;
    border-bottom-right-radius: 0!important;
  }
}
@mixin tab_sp_top_none_radius {
  @include tab {
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
  }
  @include sp {
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
  }
}
@mixin tab_sp_bottom_none_radius {
  @include tab {
    border-bottom-left-radius: 0!important;
    border-bottom-right-radius: 0!important;
  }
  @include sp {
    border-bottom-left-radius: 0!important;
    border-bottom-right-radius: 0!important;
  }
}

@mixin text_small_size {
  @include pc {
    font-size: 12px;
  }
  @include tab {
    font-size: 12px;
  }
  @include sp {
    font-size: 12px;
  }
}
@mixin text_middle_size {
  @include pc {
    font-size: 0.875rem;
  }
  @include tab {
    font-size: 0.875rem;
  }
  @include sp {
    font-size: 0.75rem;
  }
}
@mixin simple_h2 {
  font-size: 18px;
  @include pc {
    margin-bottom: 10px;
    font-weight: 700;
  }
  @include tab {
    margin-bottom: 5px;
  }
  @include sp {
    margin-bottom: 5px;
  }
}
@mixin text_bt_mg {
  @include pc {
    margin-bottom: 10px;
  }
  @include tab {
    margin-bottom: 5px;
  }
  @include sp {
    margin-bottom: 5px;
  }
}
@mixin text_p {
  @include pc {
    line-height: 1.5rem;
  }
  @include tab {
    line-height: 1.5rem;
  }
  @include sp {
    line-height: 1.5rem;
  }
}
@mixin card_width {
  font-size: 16px;
  width: 100%;
  min-width: 350px;
  box-sizing: border-box;
  border-radius: 10px 10px 0 0 / 10px 10px 0 0;
}

@mixin table_layout {
  width: 100%;
  min-width: 350px;
  box-sizing: border-box;
  overflow-y: auto;
  height: auto;
  margin: 0;
  @include pc {
  }
  @include tab {
  }
  @include sp {
  }
  th,
  td {
    border: 1px solid #dee2e6;
    vertical-align: middle;
    @include pc {
      padding: 5px 20px;
    }
    @include tab {
      font-size: 0.875rem;
      padding: 5px 20px;
    }
    @include sp {
      font-size: 0.6875rem;
      padding: 5px;
    }

  }

  th {
    background-color: rgba(0, 0, 0, 0.03);
  }

  td {
    font-weight: 400;
  }
}

@mixin header_title {
  background-color: #D5F1F3;
}

@mixin main_wrapper {
  position: relative;
  @include pc {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  @include tab {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  @include sp {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
}

@mixin main_container {
  position: relative;
  background-color: #FFF;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  @include pc {
    padding: 1.25rem!important;
  }
  @include tab {
    padding: 0.9375rem!important;
    margin-bottom: 0.9375rem;
  }
  @include sp {
    padding: 10px!important;
  }
}

@mixin form_group {
  position: relative;
  background-color: #575761;
  color: #FFF;
  border-bottom: 1px solid #dee2e6;
  @include pc {
    padding:  8px 0 8px 1.25rem!important;
    margin-bottom: 20px;
  }
  @include tab {
    padding: 5px 0!important;
    margin-bottom: 15px;
  }
  @include sp {
    padding: 5px 10px 0 10px!important;
    margin-bottom: 10px;
  }
}
@mixin m_b_15 {
  margin-bottom: 0.9375rem;
}
@mixin m_t_15 {
  margin-top: 0.9375rem;
}
@mixin result_group {
  @include pc {
    margin-top: 1rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  @include tab {
    margin-top: 1rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  @include sp {
    margin-top: 0.46875rem;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  //margin-bottom: 3.0rem; // footerの位置がずれるのでコメントアウト
}

@mixin button($bg, $color) {
  border-radius: 5px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 3px solid #439397;
  font-weight: 700;
  text-align: center;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  width: auto;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  align-items: center;
  background-color: $bg ;
  color: $color;
  &:hover {
    margin-top: 2px!important;
    border-bottom: 1px solid #439397;
    background-color: #33acb4 !important;
  }
}
@mixin button_lg_text {
    font-size: 1rem;
}
@mixin button_grey {
  background-color: #8e9db1!important;
  border-bottom: 3px solid #3a4554;
  &:hover {
    margin-top: 2px;
    border-bottom: 1px solid #3a4554;
    background-color: #7789a1 !important;
  }
}
@mixin downloadButton($color) {
  width: auto;
  align-items: center;
  background-color: white;
  color: $color;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%);
  text-align: center;
  display: flex;
  align-items: center;
  @include pc {
    font-size: 1rem;
    padding: 5px 10px;
  }
  @include tab {
    font-size: 16px;
    padding: 5px 10px;
  }
  @include sp {
    font-size: 0.75rem;
    padding: 5px 8px;
  }
}

@mixin table($th_bg, $td_bg) {
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  height: auto;
  margin: 0;
  font-weight: 400 !important;

  th,
  td {
    border: 1px solid #dee2e6;
    padding: 0.75rem;
    vertical-align: middle;
  }

  thead {
    tr {
      th {
        // width: 6.5%;
        min-width: auto;

        &:nth-of-type(1) {
          // width: 25%;
          min-width: auto;
        }

        &:nth-of-type(3) {
          // width: 62.5%;
          min-width: auto;
        }
      }

      &:nth-of-type(2) {
        th {
          // width: 12.5%;
          min-width: auto;
        }
      }
    }
  }

  th {
    background-color: $th_bg;
    min-width: auto;
  }

  td {
    min-width: auto;
    background-color: $td_bg;
    text-align: end;
  }
}

@mixin icon_face($img) {
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: $img;
}

@mixin row($size1, $size2) {
  display: block;
  justify-content: center;

  @include pc {
    display: flex;
  }

  > div {
    width: auto;

    @include pc {
      width: $size1;
    }

    &:nth-of-type(2) {
      width: auto;
      @include pc {
        width: $size2;
      }
    }
  }
}
@mixin small_text {
  @include pc {
    font-size: 12px;
  }
}
@mixin pd_block {
  @include pc {
    padding: 20px !important;
  }
}
@mixin pd_md_block {
  @include pc {
    padding: 10px !important;
  }
}
@mixin pd_block_left {
  @include pc {
    padding-left: 20px !important;
    padding-right: 0;
  }
}
@mixin pd_block_right {
  @include pc {
    padding-left: 0;
    padding-right: 20px !important;
  }
}
@mixin shadow {
  border: 0 !important;
  @include pc {
    box-shadow: 0 1px 4px rgb(0 0 0 / 25%);
  }
  @include tab {
    box-shadow: 0 1px 4px rgb(0 0 0 / 25%);
  }
  @include sp {
    box-shadow: 0 1px 4px rgb(0 0 0 / 25%);
  }
}
@mixin btn_shadow {
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  &:hover {
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }
}
@mixin radius_lg {
  @include pc {
    border-radius: 5px !important;
  }
}
@mixin action_slow {
  transition: all 0.3s ease-in-out;
}

.white_icon_S {
  @include icon_face(url('../../img/score/face_white_S.svg'));
}
.white_icon_A {
  @include icon_face(url('../../img/score/face_white_A.svg'));
}
.white_icon_B {
  @include icon_face(url('../../img/score/face_white_B.svg'));
}
.white_icon_C {
  @include icon_face(url('../../img/score/face_white_C.svg'));
}
.white_icon_D {
  @include icon_face(url('../../img/score/face_white_D.svg'));
}
.white_icon_E {
  @include icon_face(url('../../img/score/face_white_E.svg'));
}
.white_icon_F {
  @include icon_face(url('../../img/score/face_white_F.svg'));
}

.color_icon_S {
  @include icon_face(url('../../img/score/face_color_S.svg'));
}
.color_icon_A {
  @include icon_face(url('../../img/score/face_color_A.svg'));
}
.color_icon_B {
  @include icon_face(url('../../img/score/face_color_B.svg'));
}
.color_icon_C {
  @include icon_face(url('../../img/score/face_color_C.svg'));
}
.color_icon_D {
  @include icon_face(url('../../img/score/face_color_D.svg'));
}
.color_icon_E {
  @include icon_face(url('../../img/score/face_color_E.svg'));
}
.color_icon_F {
  @include icon_face(url('../../img/score/face_color_F.svg'));
}

.pc_none {
  @include pc {
    display: none;
  }
}
.sp_none {
  @include sp {
    display: none;
  }
  @include tab {
    display: none;
  }
}
.rank_score_font {
}
.result_diff {
  @include pc {
    font-size: 2.125rem;
    line-height: 2.125rem;
  }
  @include tab {
    font-size: 1.25rem;
  }
  @include sp {
    font-size: 1.25rem;
  }
}
.score_color {
  color: #666;
}
.low_score_color {
  background-color: #ffb3a7!important;
}
.low_score_icon {
  color: $danger-color;
  @include pc {
    margin-right: 5px;
    margin-top: -3px;
  }
  @include tab {
    margin-right: 5px;
  }
  @include sp {
    margin-right: 5px;
  }

}
.content_top_margin {
  @include pc {
    margin-top: 1.25rem !important;
  }
  @include tab {
    margin-top: 1.25rem !important;
  }
  @include sp {
    margin-top: 1.25rem !important;
  }
}
.content_under_margin {
  @include pc {
    margin-bottom: 1.25rem!important;
  }
  @include tab {
    margin-bottom: 1.25rem!important;
  }
  @include sp {
    margin-bottom: 1.25rem!important;
  }
}
@mixin btn_margin {
  @include pc {
    margin-right: 20px;
  }
  @include tab {
    margin-right: 10px;
  }
  @include sp {
    margin-right: 10px;
  }
}
.p_normal {
  @include pc {
    padding: 20px;
  }
  @include sp {
    padding: 10px;
  }
  @include tab {
    padding: 10px;
  }
}
.p_xs_yn {
  @include pc {
    padding: 10px 20px;
  }
  @include sp {
    padding: 10px 20px;
  }
  @include tab {
    padding: 10px 20px;
  }
}
@mixin overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: right;
  z-index: 20000;
}
@mixin block-relative{
  position: relative;
}
@mixin alert_mini_padding {
  padding: 0.525rem;
}
.card_header {
  background-color: #FFF;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include pc {
    padding: 0.75rem 1.25rem;
    line-height: 1.625rem;
  }
  @include tab {

  }
  @include sp {
    padding: 0.625rem 0.46875rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}
.white_card_header {
  background-color: $main-color;
  font-weight: 700;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include pc {
    padding: 0.75rem 1.25rem;
    line-height: 1.625rem;
  }
  @include tab {

  }
  @include sp {
    padding: 0.625rem 0.46875rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}
.ranking_arrow {
  @include pc {
    height: 33px;
    width: 33px;
  }
  @include tab {
    height: 33px;
    width: 33px;
  }
  @include sp {
    height: 22px;
    width: 22px;
  }
}
