@import '../pages/common.module.scss';
.input_wrapper {
  @include pc {
    max-width: 200px;
    margin-left: 0.9375rem!important;
  }
  @include tab {
    margin-bottom: 6px;
    margin-left: 0;
    font-size: 12px;
  }
  @include sp {
    margin-bottom: 6px;
    margin-left: 0;
    height: 30px;
    width: 50%;
  }
  input {
    @include pc {
    }
    @include tab {
      font-size: 12px;
      height: 30px;
    }
    @include sp {
      font-size: 12px;
      height: 30px;
    }
  }
}
.input_button_wrapper{
  position: relative;
  @include sp {
    width: 50%;
  }
}
.input_button{
  @include pc {
    margin-top: 5px !important;
    margin-left: 0.9375rem !important;
  }
  @include tab {
  }
  @include sp {
    position: absolute;
    right: 5px;
  }
}
.header_title {
  @include header_title;
}
.action_slow {
  @include action_slow;
}
.btn_margin {
  @include btn_margin;
}
.delete_icon {
  width: 20px;
  height: auto;
  color: #23C4BA;
  cursor: pointer;
}

.sub_belong {
  border: none;
  background-color: white;
  color: #78bcc0;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #568487;
  }
}

.form_group {
  @include form_group;
  overflow: visible !important;
}

.result_group {
  position: relative;
  @include result_group;
  overflow: visible !important;
}

.card {
  @include card_width;
  margin: auto;
  padding: 20px;
  text-align: left;
  line-height: 180%;
  
  .check_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 16px;

    > div { // TODO 削除予定
      > div {
        > label {
          margin: 15px 30px 15px 0;
          font-weight: bold;
        }
      }
    }

    > div {
      > div {
        > div {
          font-weight: bold;
          > input {
            margin-top: 6px;
            border-color: $main-color;
            &:checked {
              background-color: $main-color;
            }
          }
        }
      }
    }
  }


}
.checkbox_wrapper {
  @include pc() {
    margin: 0.625rem 1.875rem 0.625rem 0;
  }
  @include tab() {
    margin: 15px 30px 15px 0;
  }
  @include sp() {
    margin: 15px 30px 15px 0;
  }
}
.checkbox {
  > input {
    border-color: $main-color;
    &:checked {
      background-color: $main-color;
    }
  }
  margin-right: 20px;
  font-weight: bold;
}

.scroll_table {
  background-color: #FFF;
  vertical-align: middle;
  @include table_layout;
  @include pc {
    border-radius: 5px;
  }
  @include tab {
    max-height: 650px;
  }
  @include sp {
    max-height: 650px;
  }
  table {
    border-collapse: collapse;
    min-width: 100%;
    @include tab {
      width: 1200px;
    }
    @include sp {
      width: 1200px;
    }
  }

    .scroll_table_th {
    text-align: center;
    @include pc {
      padding: 5px;
      font-size: 0.8125rem;
    }
    @include tab {
      max-height: 650px;
    }
    @include sp {
      max-height: 650px;
    }
  }
  .colum_id {
    width: 1%;
  }
  .colum_name {
    width: 10%;
  }
  .colum_mail {
    width: 10%;
  }
  .colum_belong {
    width: 10%;
  }
  .colum_manager {
    width: 6.75%;
  }
  .colum_user_edit {
    width: 6.75%;
  }
  .colum_goal {
    width: 6.75%;
  }
  .colum_all_shop {
    width: 6.75%;
  }
  .colum_mail_receive {
    width: 6.75%;
  }
  .colum_gift {
    width: 6.75%;
  }
  .colum_access {
    width: 6.75%;
  }
  .colum_delete {
    vertical-align: middle;
    width: 6.75%;
  }

  td {
    padding: 0.625rem 0.3125rem;
  }

  th {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #D5F1F3;
  }

  //> thead {
  //  > tr {
  //    > th {
  //      font-weight: normal;
  //      font-style: normal;
  //      padding: 25px 10px;
  //    }
  //  }
  //}

  > tbody {
    > tr {
      > td {
        padding: 10px;
      }
    }
  }
}

.button {
  @include button(#78bcc0, white);
  display: block;
  margin-left: auto;
  margin-right: auto;
  @include pc {
    font-size: 1.125rem;
    padding: 0.375rem 0.75rem;
    width: 40%;
  }
  @include tab {
    font-size: 1rem;
    padding: 5px 10px;
  }
  @include sp {
    font-size: 1rem;
    padding: 5px 10px;
  }
  &:hover {
    background-color: #6ba5a9 !important;
  }
}

.sub_belong_button {
  @include button(#efefef, #404040);
  border-bottom: 3px solid #cecece;
  display: block;
  margin-left: auto;
  font-weight: normal;
  align-items: end;
  @include pc {
    font-size: 0.875rem;
    line-height: 0.875rem;
    padding: 0.5rem;
  }
  @include tab {
    font-size: 1rem;
    padding: 5px 10px;
  }
  @include sp {
    font-size: 1rem;
    padding: 5px 10px;
  }
  &:hover {
    border-bottom: 1px solid #c2c2c2;
    background-color: #cdcdcd !important;
  }
}
.sub_belong_button:disabled {
  @include button(#656565, #b3b3b3);
  cursor: default;
  border-bottom: 3px solid #585858;
  @include pc {
    font-size: 0.875rem;
    line-height: 0.875rem;
    padding: 0.5rem;
  }
  @include tab {
    font-size: 1rem;
    padding: 5px 10px;
  }
  @include sp {
    font-size: 1rem;
    padding: 5px 10px;
  }
  &:hover {
    margin-top: 0!important;
    border-bottom: 3px solid #585858;
    background-color: #656565 !important;
  }
}
.disable_button {
  @include button(gray, white);
}

.cancel_button {
  @include button(gray, white);

  &:hover {
    background-color: #5a5a5a !important;
  }
}
.button_lg_text{
  @include button_lg_text;
}
.unit_button {
  @include button(#78bcc0, white);
  &:hover {
    background-color: #78bcc0 !important;
  }
}

.delete_button {
  @include button(#6c757d, white);
  border-bottom: 3px solid #363943;
  border-radius: 0.25rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  margin: auto;
  @include pc {
    padding: 0.625rem 0.9375rem;
    font-size: 0.75rem;
    height: 30px;
  }
  @include tab {

  }
  @include sp {

  }
  &:hover {
    background-color: #6c757d;
  }
}

.btn_block {
  position: absolute;
  @include pc {
    right: 20px;
    top: 10px;
  }
}
.spiner_wrapper {
  @include pc {
    height: 400px;
  }
  @include tab {
    height: 200px;
  }
  @include sp {
    height: 100px;
  }
}
.spiner_absolute_wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  @include pc {
    top: 0;
    height: 400px;
  }
  @include tab {
    top: 0;
    height: 200px;
  }
  @include sp {
    top: 40px;
    height: 100px;
  }
}
.spiner_min_wrapper {
    height: 60px;
}
.sub_belong_warning {
  color: #DC3556;
  @include pc {
    font-size: 0.8125rem;
  }
  @include tab {
    font-size: 0.750rem;
  }
  @include sp {
    font-size: 0.750rem;
  }
}
