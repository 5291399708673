@import '../custom.scss';
@import 'src/scss/pages/common.module.scss';
.button_logout {
  width: auto;
  align-items: center;
  background-color: #f4f4f4;
  color: #444!important;
  border-color: #ddd;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%);
  text-align: center;
  display: flex;
  font-weight: 400;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  @include pc {
    margin-top: -5px;
  }
  @include tab {
    margin-top: -5px;
  }
  @include sp {
    margin-top: -5px;
  }
  a {
    font-size: 1rem;
    padding: 0!important;
    :hover {
      color: #888!important;
    }
  }
  :hover {
    background-color: #e7e7e7;
    a, a:hover {
      color: #888!important;
    }
  }
}
.wrapper {
  padding: 0;
  position: fixed;
  z-index: 1000;
}
.header_nav {
  @include pc {
    height: 56px;
  }
  @include tab {
    height: 50px;
  }
  @include sp {
    height: 50px;
  }
  nav {
    background-color: #FFF!important;
  }
}
.nav_bar {
  @include pc {
    height: 56px;
  }
  @include tab {
    height: 50px;
  }
  @include sp {
    height: 50px;
  }
}
.nav_buttom {
  @include pc {
    height: 32px;
    width: 32px;
  }
  @include tab {
    margin-top: -19px;
    height: 20px;
    width: 30px;
  }
  @include sp {
    margin-top: -19px;
    height: 20px;
    width: 30px;
  }
}
.header_name {
  @include pc {
    margin-right: 10px!important;
  }
  @include tab {
    margin-right: 10px!important;
  }
  @include sp {
    display: none;
    margin-right: 10px!important;
  }
}
.wrap_logo {
  @include pc {
    margin-left: 30px!important;
  }
  height: $header_height;
}

.logo {
  @include pc {
    width: 152px;
    height: 38px;
  }
  @include tab {
    width: 144px;
    height: 36px;
  }
  @include sp {
    width: 128px;
    height: 32px;
  }
}

.icon_badge {
  //background-color: #ddd;
  //border-radius: 12px;
  position: relative;
  padding: 0.5rem;
  margin: 0px;
}

.icon_badge[data-num='0']::before {
  display: none;
}

.icon_badge::before {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: attr(data-num);
  min-width: 10px;
  height: 10px;
  box-sizing: border-box;
  padding: 4px;
  font-size: 10px;
  font-weight: bold;
  background-color: #ef5350;
  border: 1px solid #fff;
  border-radius: 10px;
  top: 7px;
  transform: translate(40%, -40%);
  z-index: 1;

  left: 24px;
  @include pc {
    right: 7px;
  }
}


/*　ハンバーガーメニュー　*/
.hamburger_nemu {
  position: relative;
  width: 30px;
  height: 20px;
  background: none;
  border: none;
  appearance: none;
  cursor: pointer;

  span {
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #848788;
    border-radius: 4px;
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 8px;
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}

.hamburger_nemu_close {
  span {
    &:nth-of-type(1) {
      top: 0px;
      left: 0px;
      transform: translateY(6px) rotate(-45deg);
      width: 90%;
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      top: 0px;
      left: 0px;
      transform: translateY(6px) rotate(45deg);
      width: 90%;
    }
  }
}
.bell_icon_warpper {
  margin-right: 10px!important;
}
.admin_select {
  @include sp {
    width: 100px!important;
  }
}