@import '../pages/common.module.scss';

.wrapper {
  z-index: 2000; // headerLayoutより大きい値
  position: fixed;
  top: 0;

  @include tab {
    left: 30%;
  }

  @include pc {
    left: 40%;
  }
}

.header {
  background-color: #b1e5e5;
}

