@import './common.module.scss';

.image_wrapper {
  margin: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  @include pc {
    padding: 20px;
  }
  @include tab {
    padding: 10px;
  }
  @include sp {
    padding: 10px;
  }
}