@import '../pages/common.module.scss';

.select_icon {
  @include pc {
    font-size: 1.375rem;
    margin-right: 5px;
  }
  @include tab {
    margin-right: 3px;
  }
  @include sp {
    font-size: 1.125rem;
    margin-right: 3px;
  }
}
.select_item {
  z-index: 50;
  cursor: pointer;
  color: hsl(0, 0%, 20%);
  @include pc {
    font-size: 0.78125rem;
    height: 38px;
    line-height: 26px;
  }
  @include tab {
    font-size: 1.375rem;
  }
  @include sp {
    font-size: 0.750rem;
    height: 1.75rem;
    line-height: 1rem;
  }
}
.react_select_item {
  @include pc {
    font-size: 0.78125rem;
    height: 38px;
    line-height: 26px;
  }
  @include tab {
    font-size: 1.375rem;
  }
  @include sp {
    font-size: 0.750rem;
    height: 1.75rem;
    line-height: 1rem;
  }
}
.corporate_select {
  @include pc {
    min-width: 300px;
  }
  @include tab {
    min-width: 200px;
  }
  @include sp {
    min-width: 10px;
  }
}