$info: rgba(54, 178, 186);
$header_height: 3.5rem;
$sidebar_width: 295px;
$accordion-button-padding-y: 12px;
$accordion-button-padding-x: 20px;

@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;600;700&display=swap');
$body-font-family:'Noto Sans JP', 'Yu Gothic', YuGothic, 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', 'sans-serif';
$body-color:#848788;
html, css{
  touch-action:none;
}
body {
  margin: 0;
  font-family: $body-font-family;
  background-color: #F4F8F7;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  overflow-x: hidden;
}
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 900;
  line-height: 1.2;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
h4,
.h4 {
  font-size: 1.25rem;
}
a{
  color: #2D7BED;
  :hover {
    color: #1D4F99;
  }
}
.alert-danger {
  color: #de2c15;
  background-color: #f9c4bd;
  border-color: #de2c15;
}

// パンくずリスト
.breadcrumb-item + .breadcrumb-item::before {
  content: '>' !important;
}
