@import '../custom.scss';
@import '../pages/common.module.scss';

.wrapper {
  overflow-x: hidden;
  min-height: calc(100vh - #{$header_height});
  position: relative; /*←相対位置*/
  padding-bottom: 120px; /*←footerの高さ*/
  box-sizing: border-box; /*←全て含めてmin-height:100vhに*/
  padding: 0;
}
.narrow {
  @include pc {
    width: 1140px;
    margin-right: auto!important;
    margin-left: auto!important;
  }
  @include tab {
    width: 100%;
    margin-right: auto!important;
    margin-left: auto!important;
  }
  @include sp {
    width: 100%;
    margin-right: auto!important;
    margin-left: auto!important;
    padding-left: 0.625rem!important;
    padding-right: 0.625rem!important;
  }
}
.more_narrow {
  margin-left: auto!important;
  margin-right: auto!important;
  @include pc {
    width: 700px;
  }
  @include tab {
    width: 100%;
  }
  @include sp {
    width: 100%;
  }
}
.footer_wrapper {
  padding-top: 30px;
}

.main_footer {
  border-top: 1px solid rgba(152, 166, 173, 0.2);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  width: 100%;
  background-color: #89c7de;
  color: #fff;
  text-align: center;
  padding: 0.2rem 0;

  position: absolute; /*←絶対位置*/
  bottom: 0; /*下に固定*/
}

.breadcrumb_group {
  padding-top: 0.5rem;
}

.form_group {
  @include form_group;
}

.result_group {
  overflow: hidden;
  @include result_group;
}

.result_group_no_hidden {
  @include result_group;
}
