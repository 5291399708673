@import '../pages/common.module.scss';

.label {
  margin-top: 0;
  font-weight: 700;
  border-bottom: 1px solid #CCC;
  @include pc {
    font-size: 16px;
    padding-bottom: 15px;
  }
  @include tab {
    font-size: 14px;
    padding-bottom: 10px;
  }
  @include sp {
    font-size: 14px;
    padding-bottom: 5px;
  }
}
.news_wrapper {
  width: 100%;
  @include pc {
    padding: 5px 0;
  }
  @include tab {
    padding: 5px 0;
  }
  @include sp {
    padding: 5px 0;
  }
}
.news_title_wrapper{
  display: flex;
  width: 100%;
}
.news_time {

  @include pc {
    flex: 1 0 auto;
    width: 30%;
  }
  @include tab {
    font-size: 14px;
    padding-bottom: 10px;
  }
  @include sp {
    font-size: 14px;
    padding-bottom: 5px;
  }
}
.news_title {
  font-weight: 700;
  @include pc {
    font-size: 14px;
    flex: 0 1 auto;
    width: 70%;
  }
  @include tab {
    font-size: 14px;
    padding-bottom: 10px;
  }
  @include sp {
    font-size: 14px;
    padding-bottom: 5px;
  }
}
.news_list {
  display: flex;
  // width: 100%;
  // max-width: 350px;

  > div {
    &:nth-of-type(2n) {
      font-weight: 700;
    }
  }
}

.separate {
  width: 100%;
  border-top: dashed 1px black;
}

.detail_button {
  @include pc {
    right: 15px;
    top: 11px;
  }
  @include tab {
    right: 15px;
    top: 16px;
  }
  @include sp {
    right: 15px;
    top: 11px;
  }
  position: absolute;
  border-radius: 5px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 3px solid #439397;
  font-weight: 700;
  text-align: center;
  background-color: #23C4BA;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  width: auto;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  &:hover {
    margin-top: 2px;
    border-bottom: 1px solid #439397;
    background-color: #33acb4 !important;
  }
}
